<template>
  <div class="collective-form">
    <Title :label="`Анкета «Лига форум»/ ${currentLabel}`" icon />
    <div class="student-year__header">
      <TopSidebar :tabData="formattedLigaForumBar" />
    </div>
    <Comment
      class="student-year__comment"
      v-if="currentCategoryData?.comment_employee && currentStatusForComment"
      :comment="currentCategoryData?.comment_employee"
    />
    <router-view :currentProject="currentProject" type="Second" />
  </div>
</template>

<script>
import Title from "@/components/Blocks/Title.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import TopSidebar from "@/components/Blocks/TopSidebar.vue";
import ligaForumSecondShiftBar from "@/enums/ligaForumSecondShiftBar";
import Comment from "@/components/Blocks/Comment.vue";
import tracksKeys from "@/enums/tracksKeys";
export default {
  name: "LigaForumSecondShiftForm",
  components: { Comment, TopSidebar, Title },
  data() {
    return {
      ligaForumSecondShiftBar,
      commentStatuses: [27, 28, 35, 40],
      tracksKeys,
    };
  },
  watch: {
    user() {
      this.setProjectEducationData();
    },
  },
  computed: {
    formattedLigaForumBar() {
      let buffer = JSON.parse(JSON.stringify(this.ligaForumSecondShiftBar));

      return buffer.map((item) => {
        if (item.key === "tracks") {
          item.disabled = !this.disabledFields["isLFSecondShiftProfileFilled"];
        }
        if (item.key === "info") {
          item.disabled =
            !this.disabledFields["isLFSecondShiftTracksFilled"] ||
            !this.disabledFields["isLFSecondShiftProfileFilled"];
        }
        return item;
      });
    },
    currentProject() {
      return Object.values(this.projectsList)?.find((item) => {
        return this.currentCategoryProject
          ? item.id === this.currentCategoryProject
          : item.id === this.currentCategoryData?.info_project?.id;
      });
    },
    currentLabel() {
      return this.currentProject?.settings?.categories.find((item) => {
        return this.currentCategoryId
          ? item.category_id === this.currentCategoryId
          : item.category_id === this.currentCategoryData?.info_category?.id;
      })?.title;
    },
    currentStatusForComment() {
      return this.currentCategoryData?.status?.key
        ? this.commentStatuses.includes(this.currentCategoryData?.status?.key)
        : false;
    },
    ...mapGetters(["projectsList"]),
    ...mapState("user", ["disabledFields"]),
    ...mapState([
      "currentCategoryData",
      "currentCategoryProject",
      "currentCategoryVersion",
      "currentCategoryId",
      "secondShiftStatementData",
    ]),
  },
  methods: {
    ...mapMutations(["setShiftStatementData"]),
  },
  mounted() {
    this.setShiftStatementData({
      key: "secondShiftStatementData",
      form: this.currentCategoryData?.info_liga_forum,
    });
    this.$store.commit("user/setDisabledData", [
      `isLFSecondShiftTracksFilled`,
      this.tracksKeys.every((item) => {
        if (this.secondShiftStatementData[item]) {
          return this.secondShiftStatementData[item];
        } else return false;
      }),
    ]);
  },
};
</script>

<style lang="scss"></style>
